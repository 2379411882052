import { render, staticRenderFns } from "./website.html?vue&type=template&id=d125826e&scoped=true&external"
import script from "./Website.vue?vue&type=script&lang=js"
export * from "./Website.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d125826e",
  null
  
)

export default component.exports